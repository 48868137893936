import React from "react";
import styled from "styled-components";

import api from "../../../api";
import { MOBILE_BREAKPOINT } from "../../../constants/styles";
import useFormatMessage from "../../../hooks/useFormatMessage";
import DashboardWidget from "../DashboardWidget";

import RiskDataRow from "./RiskDataRow";

type RiskDataWidgetProps = {
  riskData: api.RiskDataMetric;
  title: string;
  infoTooltip?: string;
  loading?: boolean;
};

const RiskDataWidget = (props: RiskDataWidgetProps) => {
  const { riskData, title, infoTooltip, loading } = props;

  const f = useFormatMessage();

  return (
    <DashboardWidget title={title} infoTooltip={infoTooltip} loading={loading}>
      <StyledGridContainer>
        <StyledListContainer>
          <RiskDataRow
            label={f("dashboard.widgets.risk-data.construction-industry")}
            value={riskData.CONSTRUCTION_INDUSTRY}
          />
          <RiskDataRow
            label={f("dashboard.widgets.risk-data.high-risk-industry")}
            value={riskData.HIGH_RISK_INDUSTRY}
          />
          <RiskDataRow label={f("dashboard.widgets.risk-data.PEP")} value={riskData.PEP} />
          <RiskDataRow label={f("dashboard.widgets.risk-data.high-risk-country")} value={riskData.HIGH_RISK_COUNTRY} />
        </StyledListContainer>

        <StyledListContainer>
          <RiskDataRow label={f("dashboard.widgets.risk-data.financed-crypto")} value={riskData.FINANCED_CRYPTO} />
          <RiskDataRow
            label={f("dashboard.widgets.risk-data.financed-crowdfunding")}
            value={riskData.FINANCED_CROWDFUNDING}
          />
          <RiskDataRow
            label={f("dashboard.widgets.risk-data.missing-work-documentation")}
            value={riskData.MISSING_WORK_DOCUMENTATION}
          />
          <RiskDataRow
            label={f("dashboard.widgets.risk-data.missing-funding-documentation")}
            value={riskData.MISSING_FUNDING_DOCUMENTATION}
          />
        </StyledListContainer>
      </StyledGridContainer>
    </DashboardWidget>
  );
};

const StyledGridContainer = styled.div`
  height: 100%;
  min-height: 16rem;

  display: grid;
  grid-template-columns: 1fr 1fr;

  // For small viewports - use vertical layout
  @media (max-width: ${MOBILE_BREAKPOINT}) {
    grid-template-columns: 1fr;
    grid-auto-flow: row;
  }

  & > * + * {
    border-left: var(--p-border-divider);
  }
`;

const StyledListContainer = styled.div`
  display: grid;
  grid-template-rows: repeat(4, 1fr);

  max-height: 16rem;

  padding: var(--p-space-4);

  & > div:nth-child(even) {
    background-color: var(--p-surface-highlight-subdued-hovered);
  }
`;

export default RiskDataWidget;
