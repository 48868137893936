export enum FeaturesTypes {
  ASSIGNMENTS__HIDE_DETAILS = "ASSIGNMENTS__HIDE_DETAILS",
  ASSIGNMENT_INDUSTRIES = "ASSIGNMENT_INDUSTRIES",
  ATTACHMENTS = "ATTACHMENTS",
  AUTOMATIC_CUSTOMER_REVIEW_APPROVAL = "AUTOMATIC_CUSTOMER_REVIEW_APPROVAL",
  CUSTOMERS_LIST__BATCH_CHANGE_SCREENING_ACTION = "CUSTOMERS_LIST__BATCH_CHANGE_SCREENING_ACTION",
  CUSTOMER__HIDE_DOWNLOAD_REPORT_BUTTON = "CUSTOMER__HIDE_DOWNLOAD_REPORT_BUTTON",
  CUSTOMER__KAR_INTEGRATION = "CUSTOMER__KAR_INTEGRATION",
  DISCLOSURE_SETTINGS = "DISCLOSURE_SETTINGS",
  DISCLOSURE__PREVIEW = "DISCLOSURE__PREVIEW",
  ELECTRONIC_SIGNATURES = "ELECTRONIC_SIGNATURES",
  ENFORCE_RESPONSIBLE_USER_FILTER = "ENFORCE_RESPONSIBLE_USER_FILTER",
  EXTERNAL_ID = "EXTERNAL_ID",
  HIGHER_ACCURACY_PERCENTAGES = "HIGHER_ACCURACY_PERCENTAGES",
  HUBSPOT_LIVE_CHAT = "HUBSPOT_LIVE_CHAT",
  IMPORT_WIZARD = "IMPORT_WIZARD",
  KYC_PREVIEWER = "KYC_PREVIEWER",
  MEASURES = "MEASURES",
  PROJECTS = "PROJECTS", // allowed to list, view and create projects
  REAL_ESTATE = "REAL_ESTATE",
  RESPONSIBLE_USER = "RESPONSIBLE_USER",
  REVIEW_CHECKLIST = "REVIEW_CHECKLIST",
  REVIEW_LABELS = "REVIEW_LABELS",
  REVIEW__AUTO_SET_RISK = "REVIEW__AUTO_SET_RISK",
  REVIEW__CHECKLIST_DEFAULT_TO_NO = "REVIEW__CHECKLIST_DEFAULT_TO_NO",
  REVIEW__DISABLE_FOR_UNANSWERED_DISCLOSURES = "REVIEW__DISABLE_FOR_UNANSWERED_DISCLOSURES",
  REVIEW__DISABLE_MLRO_ADMINS_PRE_SELECTION_FOR_REVIEW_APPROVAL = "REVIEW__DISABLE_MLRO_ADMINS_PRE_SELECTION_FOR_REVIEW_APPROVAL",
  REVIEW__ONLY_LIST_COMPLIANCE_ADMIN_APPROVAL_USERS = "REVIEW__ONLY_LIST_COMPLIANCE_ADMIN_APPROVAL_USERS",
  REVIEW__SAVE_AND_CONTINUE = "REVIEW__SAVE_AND_CONTINUE",
  SCREENING__MARK_ALL_OWNERS = "SCREENING__MARK_ALL_OWNERS",
  SETTINGS__CUSTOMER_INFO_NOTIFICATIONS = "SETTINGS__CUSTOMER_INFO_NOTIFICATIONS",
  SETTINGS__REVIEW_CHECKLISTS_IMPORT_EXPORT = "SETTINGS__REVIEW_CHECKLISTS_IMPORT_EXPORT",
  VITEC_CREATE_VITEC_SPECIFIC_ASSIGNMENTS = "VITEC_CREATE_VITEC_SPECIFIC_ASSIGNMENTS",
  VITEC_PROJECTS = "VITEC_PROJECTS", // allowed to list and view, but not create projects
  VITEC_ROLES_SYNC = "VITEC_ROLES_SYNC",
  VITEC_ROLES_SYNC_WITHOUT_PHONE = "VITEC_ROLES_SYNC_WITHOUT_PHONE"
}

export type Features = Record<FeaturesTypes, boolean>;

// One of these features are required to access the project pages
export const PROJECT_FEATURES = [FeaturesTypes.PROJECTS, FeaturesTypes.VITEC_PROJECTS];
